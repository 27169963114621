import {
  AnimatedContentLoader,
  Button,
  DocumentCopyDropdown,
  Input,
  Select,
  ContactSelector,
  InlineTeaserView
} from '@components'
import { BlueprintType, BLUEPRINT_TYPE_OPTIONS, getDocumentsPageUrl } from '@constants'
import { AttachmentSelector } from '@containers/AttachmentSelector'
import { useBusinessContext, useFeature } from '@context'
import { useQueryParams } from '@hooks'
import { createDocument, fetchDocument, IDocument, updateDocument } from '@query'
import { IAccountingSuggestion } from '@query/suggestions'
import { setAPIErrors, useScreen } from '@utils'
import { getDocumentCopyValues } from '@utils/copyDocument'
import { motion } from 'framer-motion'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaSave, FaUndo } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { BlueprintEditor } from './BlueprintEditor'
import { queryKeyForAuditTrail } from '../DocumentDetails/DocumentAuditTrail'
import { cleanBlueprint } from '@utils'
import { useAllowUpload } from '@root/hooks/useAllowUpload'
import { MdOutlineRocketLaunch } from 'react-icons/md'

interface Props {
  documentId?: number
  suggestion?: IAccountingSuggestion
  onClose: () => void
  defaults?: Partial<IDocument>
  onValuesUpdate?: (values: Partial<IDocument>) => void
  enableCache?: boolean
}

export const DocumentForm: React.FC<Props> = ({
  documentId,
  defaults = {},
  onClose,
  onValuesUpdate
}) => {
  const { t } = useTranslation()
  const { businessId } = useBusinessContext()
  const queryClient = useQueryClient()
  const history = useHistory()
  const isEdit = !!documentId
  const [params] = useQueryParams()

  // DOCUMENT FETCHING
  // =================

  const {
    data: document,
    refetch,
    isLoading
  } = useQuery(
    [businessId, 'documents', documentId],
    () => fetchDocument({ businessId, documentId }),
    {
      enabled: isEdit
    }
  )

  // FORM SETUP
  // ==========

  const getDefaultValues = useCallback(
    (document?: IDocument): Partial<IDocument> => {
      const defaultValues = {
        blueprint_type: BlueprintType.PURCHASE,
        contact_id: null,
        blueprint: {},
        attachment_ids: [],
        ...defaults
      }

      if (document) {
        return {
          ...defaultValues,
          blueprint_type: document?.blueprint_type,
          date: document?.date,
          description: document?.description,
          contact_id: document?.contact_id,
          blueprint: document?.blueprint,
          attachment_ids: document?.attachment_ids
        }
      }

      return defaultValues
    },
    [defaults]
  )

  const methods = useForm<Partial<IDocument>>({
    defaultValues: async () => {
      if (isEdit) {
        const { data: document } = await refetch()
        return getDefaultValues(document)
      } else {
        return getDefaultValues()
      }
    }
  })

  const {
    watch,
    control,
    register,
    setError,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors }
  } = methods

  // PERSISTING
  // ==========

  // TODO: Form persisting has been broken some time ago.
  //  This is to be fixed in the future ticket
  // const { resetCache } = useCacheFormValues<Partial<IDocument>>({
  //   cacheKey: `${businessId}.documentForm.new`,
  //   getValues,
  //   setValue,
  //   enabled: enableCache,
  //   defaultValues: getDefaultValues
  // })

  const isAllowedToUpload = useAllowUpload()
  const hasUnlimitedStorage = useFeature('feature_unlimited_storage', true)

  // WATCH VALUES
  // ============

  const values = watch()
  useEffect(() => {
    if (onValuesUpdate) onValuesUpdate(values)
  }, [JSON.stringify(values)])

  const showFileTeaser = !hasUnlimitedStorage && values.attachment_ids?.length === 0

  // FORM RESET
  // ==========

  const resetForm = useCallback(() => {
    const options = {
      keepErrors: false,
      keepDirty: false,
      keepDirtyValues: false,
      keepValues: false,
      keepDefaultValues: true
    }
    reset(undefined, options)

    // For some reason the above code won't reset field arrays
    // but this thing here fixes it:
    if (!isEdit)
      reset(
        {
          blueprint: {
            debet_account_id: null,
            credit_account_id: null,
            debet_entries: [{}] as any,
            credit_entries: [{}] as any
          }
        },
        options
      )

    // resetCache()
  }, [])

  // CONTACT SUGGESTION
  // ==================

  // Enable suggestion if document is already loaded, document is not draft
  // and contact is not already selected
  const enableContactSuggestion = !!document?.import_data?.contact_hint && !watch('contact_id')

  // COPY ANOTHER DOCUMENT
  // =====================

  const onCopy = useCallback(documentToCopy => {
    reset(
      {
        ...getValues(),
        ...getDocumentCopyValues(documentToCopy, isEdit ? document.import_data : null)
      },
      { keepDefaultValues: true }
    )
  }, [])

  // CREATION & UPDATING
  // ===================

  const options = useMemo(
    () => ({
      onSuccess: async (data: IDocument) => {
        queryClient.invalidateQueries({ queryKey: [businessId, 'documents'] })
        queryClient.invalidateQueries(queryKeyForAuditTrail(businessId, data.id))
        queryClient.invalidateQueries([businessId, 'balanceCheck'])
        await queryClient.invalidateQueries({ queryKey: [businessId, 'documents', data.id] })

        if (!isEdit) resetForm()
        onClose()

        history.push(getDocumentsPageUrl(businessId, { ...params, id: data.id }))
        toast.success(isEdit ? t('document.form.updateSuccess') : t('document.form.createSuccess'))
      },
      onError: ({ status, data }) => {
        if (status === 400) {
          setAPIErrors(data, setError)

          if (data.blueprint?.common) {
            toast.error(data.blueprint?.common[0])
          }
        } else {
          toast.error(t('document.form.updateError'))
        }
      }
    }),
    [params, onClose]
  )

  const createMutation = useMutation<IDocument, unknown, Partial<IDocument>>(
    data => createDocument({ businessId }, data),
    options
  )
  const updateMutation = useMutation<IDocument, unknown, Partial<IDocument>>(
    data => updateDocument({ businessId, documentId: document.id }, data),
    options
  )

  const onSubmit = handleSubmit(data => {
    const newData = {
      ...data,
      blueprint: cleanBlueprint(data?.blueprint_type, data?.blueprint)
    }
    isEdit ? updateMutation.mutate(newData) : createMutation.mutate(newData)
  })

  const aiHelpSrc = getDocumentsPageUrl(businessId, { ...params, extra: 'ai-help' })
  const { md } = useScreen()

  return (
    <AnimatedContentLoader isLoading={isLoading}>
      <FormProvider {...methods}>
        <StyledForm onSubmit={onSubmit} data-test="document-form">
          <StyledFormContent>
            {!md && (
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 4 }}>
                <Link to={aiHelpSrc}>{t('document.helpNeeded')}</Link>
              </div>
            )}

            {/* DATE */}
            <Input
              data-test="document-form-date-input"
              type="date"
              id="document-date"
              placeholder="YYYY-MM-DD"
              autoFocus={true}
              label={t('document.date')}
              info={t('document.dateInfo')}
              required={true}
              {...register('date', {
                required: t('validation.required') as string
              })}
              errors={errors.date}
            />

            {/* DESCRIPTION & COPY FUNCTIONALITY */}
            <Controller
              control={control}
              name="description"
              rules={{ required: { value: true, message: t('validation.required') } }}
              render={({ field, fieldState: { error } }) => (
                <DocumentCopyDropdown
                  key="document-copy-dropdown"
                  label={t('document.description')}
                  placeholder={t('document.descriptionPlaceholder')}
                  info={t('document.descriptionInfo')}
                  id="document-description"
                  businessId={businessId}
                  onCopy={onCopy}
                  errors={error}
                  required={true}
                  excludeIds={documentId ? [documentId] : null}
                  importModelId={document?.import_data?.id}
                  {...field}
                />
              )}
            />

            {/* CONTACT & CONTACT SUGGESTIONS */}
            <Controller
              name="contact_id"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <ContactSelector
                  {...field}
                  errors={error}
                  ref={null}
                  clearSelectedItem={() => setValue('contact_id', null)}
                  required={false}
                  isSuggestionEnabled={enableContactSuggestion}
                  suggestionQuery={{ query: document?.import_data?.contact_hint }}
                  fetchOnlyInvoicableContacts={false}
                  placeholder={t('document.contactPlaceholder')}
                  label={t('document.contact')}
                  info={t('document.contactInfo')}
                  noDataMessage={t('contacts.contactSelector.noDataDocumentForm2')}
                  isQuickAddEnabled={true}
                ></ContactSelector>
              )}
            />

            <br />

            <motion.div layout="position">
              <Controller
                name="blueprint_type"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Select
                    data-test="document-form-type-select"
                    name="blueprint_type"
                    label={t('document.selectedType')}
                    onChange={onChange}
                    value={value}
                    errors={error}
                    required={true}
                  >
                    {BLUEPRINT_TYPE_OPTIONS.map(({ value, labelKey }) => (
                      <option key={value} value={value}>
                        {t(labelKey)}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </motion.div>

            <BlueprintWrapper>
              <BlueprintEditor
                expectedPaymentAccount={document?.import_data?.payment_account_id}
                expectedPaymentAccountAmount={document?.import_data?.amount}
              />
            </BlueprintWrapper>

            <br />
            <h4>{t('document.attachments')}</h4>
            {showFileTeaser ? (
              <InlineTeaserView
                icon={<MdOutlineRocketLaunch />}
                iconText={t('inlineTeaser.iconText')}
                text={t('inlineTeaser.files')}
              />
            ) : (
              <Controller
                name="attachment_ids"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <AttachmentSelector
                    value={value}
                    onChange={onChange}
                    showRemoveInsteadOfViewLink={true}
                    disabled={!isAllowedToUpload}
                  />
                )}
              />
            )}
          </StyledFormContent>
          <ButtonFooter>
            <Button type="button" onClick={() => onClose()} data-test="document-form-cancel-button">
              {t('general.cancel')}
            </Button>
            <Button
              tabIndex={-1}
              type="button"
              icon={<FaUndo />}
              onClick={() => {
                resetForm()
              }}
            >
              {t('general.startOver')}
            </Button>

            <div style={{ flex: 1 }} />

            <Button
              data-test="document-form-save-button"
              icon={<FaSave />}
              type="submit"
              intent="success"
              disabled={updateMutation.isLoading || createMutation.isLoading}
              showSpinner={updateMutation.isLoading || createMutation.isLoading}
            >
              {t('general.save')}
            </Button>
          </ButtonFooter>
        </StyledForm>
      </FormProvider>
    </AnimatedContentLoader>
  )
}

const BlueprintWrapper = styled.div`
  border: 3px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.md}rem;
`

const ButtonFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${({ theme }) => theme.colors.neutralGray};
  padding-top: ${({ theme }) => theme.spacing.lg}rem;
  gap: ${({ theme }) => theme.spacing.xs}rem;
`

const StyledForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const StyledFormContent = styled.div`
  flex: 1;
  overflow: auto;
`

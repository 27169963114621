import { DraggableVirtualList, IDraggableItem } from '@components'
import { useBusinessContext } from '@context'
import { IBalanceCheck, IDocument } from '@query'
import dayjs from 'dayjs'
import { motion } from 'framer-motion'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { DocumentListItem } from './DocumentListItem'
import { DocumentTrash } from './DocumentTrash'

interface Props {
  documents: IDocument[]
  balanceCheckResult?: IBalanceCheck
  onScrollBottom: () => Promise<any>
}

interface IDateBadge {
  id: string
  title: string
}

interface IDocumentElement {
  id: number
  isFirstOfType: boolean
  isLastOfType: boolean
  balanceResult?: IBalanceCheck
  document: IDocument
}

interface IBalanceMarkerElement {
  id: string
  result: IBalanceCheck
}

interface DraggableBadgeOrDocument {
  type: 'document' | 'badge' | 'balanceMarker'
  allowDrag: boolean
  allowDrop: boolean
  item: IDateBadge | IDocumentElement | IBalanceMarkerElement
}

export const DocumentList: React.FC<Props> = ({
  documents,
  balanceCheckResult,
  onScrollBottom
}) => {
  const { businessId } = useBusinessContext()

  const documentsAndBadges = useMemo<DraggableBadgeOrDocument[]>(() => {
    const docsAndBadges: DraggableBadgeOrDocument[] = []
    const seenDocuments = new Set<number>()

    documents.forEach((document, index) => {
      const lastDoc = documents[index - 1]
      const nextDoc = documents[index + 1]

      const thisDate = dayjs(document.date, 'YYYY-MM-DD').format('MMMM YYYY')
      const lastDate = lastDoc && dayjs(lastDoc.date, 'YYYY-MM-DD').format('MMMM YYYY')
      const nextDate = nextDoc && dayjs(nextDoc.date, 'YYYY-MM-DD').format('MMMM YYYY')

      const isFirstOfType = thisDate !== lastDate
      const isLastOfType = thisDate !== nextDate

      // Duplicate check in case react-query has re-fetched only one page
      // which can briefly cause duplicates before the next page is fetched
      if (seenDocuments.has(document.id)) return
      seenDocuments.add(document.id)

      if (isFirstOfType) {
        docsAndBadges.push({
          type: 'badge',
          allowDrag: false,
          allowDrop: false,
          item: {
            id: thisDate,
            title: thisDate
          }
        })
      }

      docsAndBadges.push({
        type: 'document',
        allowDrag: true,
        allowDrop: false,
        item: {
          id: document.id,
          isFirstOfType,
          isLastOfType,
          balanceResult:
            document.id === balanceCheckResult?.first_invalid?.document_id
              ? balanceCheckResult
              : null,
          document
        }
      })
    })

    return docsAndBadges
  }, [documents, JSON.stringify(balanceCheckResult)])

  return (
    <DocumentListWrapper>
      <DraggableVirtualList
        items={documentsAndBadges}
        itemType="document"
        itemSize={96}
        onScrollBottom={onScrollBottom}
        render={(item, { isSelected }) => {
          if (item.type === 'badge') {
            const dateBadge = item.item as IDateBadge

            return (
              <DateBadgeWrapper key={dateBadge.id}>
                <DateBadge>{dateBadge.title}</DateBadge>
              </DateBadgeWrapper>
            )
          } else {
            const { document, isLastOfType, isFirstOfType, balanceResult } =
              item.item as IDocumentElement

            return (
              <DocumentListItem
                key={`document-${document.id}`}
                document={document}
                isSelected={isSelected}
                businessId={businessId}
                isFirstOfType={isFirstOfType}
                isLastOfType={isLastOfType}
                balanceResult={balanceResult}
              />
            )
          }
        }}
        renderDrag={items => <DragLayer items={items} />}
      />

      <DocumentTrash />
    </DocumentListWrapper>
  )
}

const DragLayer: React.FC<{ items: IDraggableItem[] }> = ({ items }) => {
  const totalItems = items.length
  const renderableItems = items.slice(0, 5)
  const { businessId } = useBusinessContext()

  return (
    <StyledDragLayer>
      {renderableItems.map(({ item }, index) => (
        <StyledDragItem key={`document-${item.id}`} index={index} count={renderableItems.length}>
          <DocumentListItem
            document={(item as IDocumentElement).document}
            isSelected={false}
            businessId={businessId}
            isFirstOfType={true}
            isLastOfType={true}
          />
        </StyledDragItem>
      ))}
      <DragCount>{totalItems}</DragCount>
    </StyledDragLayer>
  )
}

const StyledDragLayer = styled.div`
  position: relative;
  max-width: 400px;
`

const StyledDragItem = styled(motion.div)<{ index: number; count: number }>`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1rem;
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.1);
  z-index: ${({ count, index }) => 10 + count - index};
  transform: translate(${({ index }) => index * 4}px, ${({ index }) => index * 4}px);
`

const DragCount = styled.div`
  background: ${({ theme }) => theme.colors.nocfoGreen};
  color: ${({ theme }) => theme.colors.neutralWhite};
  font-weight: bold;
  padding: ${({ theme }) => theme.spacing.xs}rem;
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  min-width: 1.6rem;
  height: 1.6rem;
  transform: translate(50%, 50%);
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  z-index: 20;
`

const DocumentListWrapper = styled.div`
  flex: 1;
  height: 100%;
  overflow: auto;
  position: relative;
  overflow: hidden;
`

const DateBadgeWrapper = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;

  & > * {
    align-self: center;
  }
`

const DateBadge = styled.div`
  text-transform: uppercase;
  padding: 0.2rem 0.4rem;
  background: ${({ theme }) => theme.colors.neutralBlack};
  color: ${({ theme }) => theme.colors.metalGray};
  border-radius: 1rem;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: 500;
  background: ${({ theme }) => theme.colors.neutralGray};
`

import { network } from '@utils'
import { QueryFunction } from './root'

export interface IFeatures {
  is_ny: boolean
  is_billable: boolean
  vat_obliged: boolean
  has_history: boolean
  feature_csv_import: boolean
  feature_unlimited_storage: boolean
  feature_invoicing: boolean
  feature_bank_integrations: boolean
  feature_analyze_attachments: boolean
  feature_einvoicing: boolean
  feature_show_received_einvoices: boolean
  feature_financial_statement: boolean
  feature_premium_support: boolean
  feature_salaries: boolean
  feature_white_label_reports: boolean
  feature_document_copy: boolean
  feature_unlimited_users: boolean
  feature_bulk_invoicing: boolean
  feature_purchase_invoice_email_import: boolean
  feature_balance_check: boolean
}

interface FeatureInstanceProps {
  businessId: string
}

export const fetchFeatures: QueryFunction<FeatureInstanceProps, IFeatures> = async ({
  businessId
}) => {
  const url = `/v1/business/${businessId}/features/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}

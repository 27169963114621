type KeyPair = { [key: string]: any }

export const parseQuery = (params: KeyPair): string => {
  let queryString = ''

  Object.keys(params).forEach(key => {
    if (params[key] !== undefined && params[key] !== null) {
      queryString += `&${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    }
  })

  return queryString ? `?${queryString.slice(1)}` : ''
}

import { Button, FileIcon, FilePreview } from '@components'
import { useBusinessContext } from '@context'
import { useLocalStorage } from '@hooks'
import { attachFiles, fetchSuggestedAttachments, IDocument, IFile } from '@query'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FaMagic, FaPaperclip, FaTimes } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'

interface Props {
  document: IDocument
}

export const AttachmentSuggestions: React.FC<Props> = ({ document }) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const [isHidden, setIsHidden] = useLocalStorage(
    `${businessId}.document.${document.id}.hideAttachmentSuggestions`,
    false
  )

  const isSuggestionsEnabled = useMemo(() => {
    return !(document.attachment_ids?.length > 0) && !document.is_locked && !isHidden
  }, [document.attachment_ids])

  const { data } = useQuery(
    [businessId, 'documents', document.id, 'suggested_files'],
    () => fetchSuggestedAttachments({ businessId, documentId: document.id }),
    { enabled: isSuggestionsEnabled }
  )
  const suggestions = data ? data.slice(0, 3) : []
  const hideSuggestions = suggestions.length === 0 || isHidden || !isSuggestionsEnabled

  return (
    <AnimatePresence>
      {!hideSuggestions && (
        <StyledSuggestions>
          <StyledHeader>
            <StyledHeaderText>
              <FaMagic size={24} /> <span>{t('document.suggestions.title')}</span>
            </StyledHeaderText>
            <Button
              isSecondary={true}
              icon={<FaTimes size={16} />}
              onClick={() => setIsHidden(true)}
            />
          </StyledHeader>
          <StyledBody>{t('document.suggestions.description')}</StyledBody>
          {suggestions.map(attachment => (
            <Suggestion
              key={`suggestion-${attachment.id}`}
              documentId={document.id}
              attachment={attachment}
            />
          ))}
        </StyledSuggestions>
      )}
    </AnimatePresence>
  )
}

interface SuggestionProps {
  documentId: number
  attachment: IFile
}

const Suggestion: React.FC<SuggestionProps> = ({ attachment, documentId }) => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()

  const { mutate, isLoading } = useMutation(
    () => attachFiles({ businessId, documentId }, [attachment.id]),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'documents', documentId])
      }
    }
  )

  return (
    <StyledSuggestion>
      <FilePreview fileId={attachment.id}>
        <div className="file-icon-wrapper">
          <FileIcon
            size={32}
            filetype={attachment.type}
            filename={attachment.name}
            thumb={attachment.file}
          />
        </div>
      </FilePreview>
      <Button
        icon={<FaPaperclip />}
        onClick={() => {
          mutate()
        }}
        showSpinner={isLoading}
        disabled={isLoading}
        isSecondary
      >
        {t('document.suggestions.attach')}
      </Button>
    </StyledSuggestion>
  )
}

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  margin-bottom: ${({ theme }) => theme.spacing.xs}rem;
`

const StyledHeaderText = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.nocfoPurple};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  margin-bottom: 0;
`

const StyledBody = styled.p`
  color: ${({ theme }) => theme.colors.neutralBlack};
`

const StyledSuggestions = styled(motion.div).attrs({
  initial: {
    scale: 0.95,
    opacity: 0,
    y: 10
  },
  animate: {
    scale: 1,
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.5
    }
  },
  exit: {
    scale: 0.95,
    opacity: 0,
    y: 10
  }
})`
  margin-top: ${({ theme }) => theme.spacing.md}rem;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
  background: ${({ theme }) => theme.colors.nocfoPurple}11;
  border-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.md}rem;
  border: 3px solid ${({ theme }) => theme.colors.nocfoPurple};
`

const StyledSuggestion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .file-icon-wrapper > * {
    margin: 0;
  }
`

import { useDateRangeContext } from '@context'
import { shift } from '@floating-ui/react'
import { autoUpdate, offset, useDismiss, useFloating } from '@floating-ui/react-dom-interactions'
import { formatDate } from '@utils'
import cn from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCalendar, FaCaretDown, FaTimes } from 'react-icons/fa'
import { DateRangeDropdown } from './DateRangeDropdown'
import {
  StyledDateRangeSelect,
  StyledFilterLabel,
  StyledIconWrapper
} from './DateRangeSelect.styled'

export const DateRangeSelect: React.FC<{ compact: boolean }> = ({ compact }) => {
  const { t } = useTranslation()
  const { selectedRange, isActive, isReady, reset, refetch } = useDateRangeContext()
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const { context, x, y, reference, floating, strategy } = useFloating({
    open: dropdownOpen,
    onOpenChange: setDropdownOpen,
    placement: 'bottom-start',
    middleware: [offset(10), shift()],
    whileElementsMounted: autoUpdate
  })

  useDismiss(context)

  return (
    <>
      <StyledDateRangeSelect
        className={cn('date-range-select', { isActive })}
        ref={reference}
        onClick={() => {
          refetch()
          setDropdownOpen(!dropdownOpen)
        }}
        data-test="date-range-select"
      >
        <StyledIconWrapper className={cn({ isActive })}>
          <FaCalendar />
        </StyledIconWrapper>
        {!compact && (
          <>
            <StyledFilterLabel>
              <div>
                {selectedRange?.key ? t(`components.dateRangeFilter.${selectedRange?.key}`) : ''}
              </div>
              {selectedRange?.key === 'custom' && (
                <span>
                  {selectedRange?.date_from && formatDate(selectedRange?.date_from)} -{' '}
                  {selectedRange?.date_to && formatDate(selectedRange?.date_to)}
                </span>
              )}
            </StyledFilterLabel>{' '}
            {isActive ? (
              <FaTimes
                className="reset-btn"
                onClick={e => {
                  reset()
                  e.stopPropagation()
                }}
                data-test="reset-date-range-button"
              />
            ) : (
              <FaCaretDown />
            )}
          </>
        )}
      </StyledDateRangeSelect>

      {isReady && (
        <DateRangeDropdown
          ref={floating}
          isVisible={dropdownOpen}
          context={context}
          x={x}
          y={y}
          strategy={strategy}
        />
      )}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { useJobContext } from '@containers/CSVImportWizard/base/ImportJobContext'
import { ImportJobTracker } from './ImportJobTracker'
import { useQueries } from 'react-query'
import { useBusinessContext } from '@context'
import { fetchImportJob, IImportJob } from '@query/importJob'
import toast from 'react-hot-toast'

export const ImportJobsTracker: React.FC = React.memo(() => {
  const { businessId } = useBusinessContext()
  const { trackedJobs, untrack } = useJobContext()
  const [dismissedIds, setDismissedIds] = useState<string[]>([])

  const results = useQueries(
    (trackedJobs || []).map(importJobId => ({
      queryKey: [businessId, 'import-job', importJobId],
      queryFn: () => fetchImportJob({ businessId, importJobId }),
      refetchInterval: 2000,
      enabled: !dismissedIds.includes(importJobId),
      onError: ({ status }) => {
        if (status === 404) {
          untrack(importJobId)
        }
      }
    }))
  )

  const getToastId = (job: IImportJob) => `import-job-${job?.id}`

  useEffect(
    () =>
      setDismissedIds(results?.filter(({ data }) => data?.finished_at).map(({ data }) => data?.id)),
    [JSON.stringify(results?.map(({ data }) => data?.finished_at))]
  )

  results
    .filter(({ isSuccess }) => isSuccess)
    .map(({ data }) =>
      toast.custom(
        <ImportJobTracker
          key={`import-job-${data?.id}`}
          businessId={businessId}
          data={data}
          onDismiss={() => {
            toast.remove(getToastId(data))
            untrack(data.id)
          }}
        />,
        {
          id: getToastId(data),
          duration: Infinity,
          style: {
            width: '400px'
          }
        }
      )
    )

  return null
})

import { MonetaryAmount } from '@components'
import cn from 'classnames'
import { motion } from 'framer-motion'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
  to: string
  icon: React.ReactNode
  iconColorHex: string
  isFirst: boolean
  isLast: boolean

  // Fields
  date: string | React.ReactNode
  contact: string
  amount: number
  statusDot: React.ReactNode
}

export const GeneralListItem: React.FC<Props> = ({
  to,
  icon,
  iconColorHex,
  isFirst,
  isLast,
  date,
  contact,
  amount,
  statusDot
}) => (
  <StyledListItem to={to} className={cn({ isFirst, isLast })} data-test="invoice-list-item">
    <StyledListItemIcon iconColor={iconColorHex}>{icon}</StyledListItemIcon>
    <StyledListItemRows>
      <StyledListItemRow>
        <InvoiceDate>
          <span>{date}</span>
        </InvoiceDate>
        <InvoiceDate>{statusDot}</InvoiceDate>
      </StyledListItemRow>
      <StyledListItemRow>
        <InvoiceContactName>{contact}</InvoiceContactName>
        <InvoiceAmount>
          <MonetaryAmount showColor value={amount} />
        </InvoiceAmount>
      </StyledListItemRow>
    </StyledListItemRows>
  </StyledListItem>
)

const StyledListItem = styled(Link)`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md}rem;
  background: ${({ theme }) => theme.colors.neutralWhite};
  padding: ${({ theme }) => theme.spacing.md}rem;
  border-bottom: 0.5px solid #f6f7f9;

  &.isFirst {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  &.isLast {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  &:hover,
  &.isSelected {
    text-decoration: none;
    background: ${({ theme }) => theme.colors.defaultHover};
  }
`

const StyledListItemIcon = styled(motion.div).attrs({
  whileHover: {
    scale: 1.1
  }
})<{ iconColor: string }>`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ iconColor }) => iconColor}22;

  svg {
    fill: ${({ iconColor }) => iconColor};
    width: 1rem;
    height: 1rem;
  }
`

const StyledListItemRows = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const StyledListItemRow = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 17px;

  * {
    align-self: center;
  }

  &:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing.xxs}rem;
  }
`

const InvoiceDate = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.metalGray};
`

const InvoiceContactName = styled.span`
  color: ${({ theme }) => theme.colors.neutralBlack};
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0.6rem;
  transition: 0.2s;
`

const InvoiceAmount = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
`

import { useCallback, useEffect, useState } from 'react'

const BASE_KEY = 'nocfo-app'

function getNestedValue(obj: any, path: string[]): any {
  return path.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj)
}

function setNestedValue(obj: any, path: string[], value: any): void {
  let current = obj
  for (let i = 0; i < path.length - 1; i++) {
    const key = path[i]
    if (current[key] === undefined) current[key] = {}
    current = current[key]
  }
  current[path[path.length - 1]] = value
}

function getLocalStorageObj(): any {
  try {
    const raw = window.localStorage.getItem(BASE_KEY)
    return raw ? JSON.parse(raw) : {}
  } catch {
    return {}
  }
}

function saveLocalStorageObj(data: any): void {
  try {
    window.localStorage.setItem(BASE_KEY, JSON.stringify(data))
  } catch (error) {
    console.log(error)
  }
}

export function useLocalStorage<T>(dotPath: string, initialValue: T) {
  const pathArray = dotPath.split('.')
  const [storedValue, setStoredValue] = useState<T>(() => {
    const obj = getLocalStorageObj()
    const existing = getNestedValue(obj, pathArray)
    return existing === undefined ? initialValue : existing
  })

  const setValue = useCallback(
    (value: T | ((prev: T) => T)) => {
      if (!dotPath) return

      setStoredValue(prev => {
        const valToStore = typeof value === 'function' ? (value as (p: T) => T)(prev) : value
        const data = getLocalStorageObj()
        setNestedValue(data, pathArray, valToStore)
        saveLocalStorageObj(data)
        return valToStore
      })
    },
    [dotPath]
  )

  useEffect(() => {
    if (!dotPath) return

    const data = getLocalStorageObj()
    const existing = getNestedValue(data, pathArray)
    if (existing === undefined) {
      setNestedValue(data, pathArray, initialValue)
      saveLocalStorageObj(data)
      setStoredValue(initialValue)
    } else {
      setStoredValue(existing)
    }
  }, [dotPath, JSON.stringify(initialValue)])

  return [storedValue, setValue] as const
}

import { useBusinessContext } from '@root/context'
import { fetchAlerts } from '@root/query'
import React from 'react'
import { useQuery } from 'react-query'
import AlertCard, { EmptyAlertCard } from './AlertCard'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { AnimatedContentLoader } from '@components'
import { useLocalStorage } from '@hooks'
import { uniq } from 'lodash'

const Alerts: React.FC = () => {
  const { businessId } = useBusinessContext()
  const [dismissedTypes, setDismissedTypes] = useLocalStorage(
    `${businessId}.dashboardAlerts.dismissedTypes`,
    []
  )
  const { data: _businessAlerts, isLoading } = useQuery([businessId, 'dashboard_alerts'], () =>
    fetchAlerts({ businessId })
  )

  const businessAlerts =
    _businessAlerts?.filter(({ type }) => dismissedTypes.indexOf(type) === -1) || []

  const isEmpty = !(businessAlerts && businessAlerts.length > 0)

  return (
    <ScrollableContentWrapper>
      <AnimatedContentLoader
        isLoading={isLoading}
        isEmpty={isEmpty}
        isEmptyContent={
          <ScrollableContent>
            <EmptyAlertCard />
          </ScrollableContent>
        }
      >
        <ScrollableContent>
          {businessAlerts?.map(data => (
            <AnimatedCard key={data?.type}>
              <AlertCard
                alert={data}
                handleOnDismiss={() => setDismissedTypes(uniq([...dismissedTypes, data?.type]))}
              />
            </AnimatedCard>
          ))}
        </ScrollableContent>
      </AnimatedContentLoader>
    </ScrollableContentWrapper>
  )
}

export default Alerts

const ScrollableContentWrapper = styled.div`
  position: relative;
  min-height: 180px;
  max-height: 180px;
  flex: 1;
`

export const ScrollableContent = styled(motion.div).attrs({
  variants: {
    hide: {
      opacity: 0,
      transition: {
        duration: 0.4,
        staggerChildren: 0.4,
        delayChildren: 0.3,
        staggerDirection: -1,
        type: 'tween'
      }
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.4,
        staggerChildren: 0.4,
        delayChildren: 0.3,
        type: 'tween'
      }
    }
  },
  initial: 'hide',
  animate: 'show'
})`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.md}rem;

  // hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: 100%;
`

const AnimatedCard = styled(motion.div).attrs({
  layout: true,
  variants: {
    hide: {
      opacity: 0
    },
    show: {
      opacity: 1
    },
    whileHover: {
      scale: 0.95,
      transition: { type: 'spring', stiffness: 400, damping: 14, duration: 1 }
    },
    whileTap: {
      scale: 0.98,
      transition: {
        type: 'spring',
        stiffness: 400,
        damping: 14,
        duration: 1
      }
    }
  },
  initial: 'hide',
  animate: 'show',
  whileHover: 'whileHover',
  whileTap: 'whileTap'
})``

import {
  AnimatedContentLoader,
  DraggableVirtualList,
  IDraggableItem,
  UploadDropzone
} from '@components'
import { useBusinessContext } from '@context'
import { useLocalStorage, useQueryParam } from '@hooks'
import {
  collectFromPages,
  fetchFiles,
  fetchFolder,
  fetchFolders,
  IFile,
  IFolderMoveAction,
  ISubFolder,
  moveItemsToFolder,
  uploadFile
} from '@query'
import { motion } from 'framer-motion'
import React, { useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { FilesActionBar } from './FilesActionBar'
import { BreadCrumb } from './BreadCrumb'
import { FileItem, Folder } from './Folder'
import { useAllowUpload } from '@hooks/useAllowUpload.tsx'
import { IFilter } from '@root/components/ActionBar/FilterBar/FilterSelection'

interface Props {
  upperWidget?: React.ReactNode
}

export const FileBrowser: React.FC<Props> = ({ upperWidget }) => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const [folderId] = useQueryParam('folder')
  const { businessId } = useBusinessContext()

  const [searchValue, setSearchValue] = useState('')
  const [filters, setFilter] = useLocalStorage<IFilter[]>(`${businessId}.filters.files`, [])

  const allowUpload = useAllowUpload()

  const uploadMutation = useMutation<IFile, unknown, File>(
    file => uploadFile(file, { businessId, fileName: file.name, folderId }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'files'])
      },
      onError: ({ status }) => {
        if (status == 426) {
          toast.error(t('error.upgradeRequired'))
        }
      }
    }
  )

  const moveMutation = useMutation<unknown, unknown, IFolderMoveAction>(
    data => moveItemsToFolder({ businessId }, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'files'])
        await queryClient.invalidateQueries([businessId, 'folders'])
      }
    }
  )

  const handleOnDrop = (target: IDraggableItem, items: IDraggableItem[]) => {
    if (target.type === 'folder' && items.length > 0) {
      const movePromise = moveMutation.mutateAsync({
        target_folder_id: target.item.id,
        items: items.map(item => ({
          id: item.item.id,
          type: item.type
        }))
      })

      toast.promise(movePromise, {
        loading: t('files.browser.moving'),
        success: t('files.browser.moveSuccess'),
        error: t('errors.oho')
      })
    }
  }

  const fetchFoldersForView = useCallback(async (folderId?: number) => {
    // Fetch specific folder contents if folder is open. Otherwise fetch from root.
    if (folderId) {
      const data = await fetchFolder({ businessId, folderId })
      return { folders: data.children, breadcrumbs: data.breadcrumbs }
    } else {
      const data = await fetchFolders({ businessId }, { page_size: 9999 })
      return { folders: data.results, breadcrumbs: [] }
    }
  }, [])

  const hideFolders = !!searchValue || Object.keys(filters).length !== 0

  const { data, isLoading: isFoldersLoading } = useQuery(
    [businessId, 'folders', folderId],
    () => fetchFoldersForView(folderId),
    {
      enabled: !hideFolders // hide folders on search
    }
  )

  const folders = data?.folders
  const breadcrumbs = data?.breadcrumbs || []

  const queryParams = {
    search: searchValue ? searchValue : null,
    folder: searchValue ? null : folderId,
    root: hideFolders ? null : !folderId,
    ...filters?.reduce((prev, current) => ({ ...prev, ...current }), {})
  }

  const {
    data: fileData,
    isLoading: isFilesLoading,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(
    [businessId, 'files', queryParams],
    ({ pageParam }) =>
      fetchFiles({ businessId }, { ...queryParams, page: pageParam ? pageParam : 1 }),
    {
      getNextPageParam: lastPage => lastPage.next,
      getPreviousPageParam: lastPage => lastPage.prev
    }
  )
  const files = collectFromPages<IFile>(fileData)

  const foldersAndFiles = useMemo(
    () => [
      ...(hideFolders // hide folders on search
        ? []
        : folders?.map(item => ({
            type: 'folder',
            allowDrop: allowUpload,
            allowDrag: allowUpload ? !searchValue : false, // disable drag on search,
            item
          })) || []),
      ...(files?.map(item => ({
        type: 'file',
        allowDrop: false,
        allowDrag: allowUpload ? !searchValue : false, // disable drag on search
        item
      })) || [])
    ],
    [folders, files]
  )

  return (
    <UploadDropzone uploadFile={uploadMutation.mutateAsync} disabled={!allowUpload}>
      <FileBrowserWrapper>
        <FilesActionBar
          onSearch={value => setSearchValue(value)}
          onFilter={filters => setFilter(filters || {})}
          activeFilters={filters}
        />

        {upperWidget && <UpperWidget>{upperWidget}</UpperWidget>}

        <BreadCrumb breadcrumbs={breadcrumbs} searchValue={searchValue} />

        <ItemListWrapper>
          <AnimatedContentLoader
            isLoading={isFilesLoading || isFoldersLoading}
            isEmpty={foldersAndFiles.length === 0}
            isEmptyDescription={
              searchValue ? t('files.browser.isEmptySearch') : t('files.browser.isEmpty')
            }
          >
            <DraggableVirtualList
              itemType="file-item"
              itemSize={50}
              items={foldersAndFiles}
              onScrollBottom={() => hasNextPage && fetchNextPage()}
              render={(
                item,
                { isDragging, isSelected, isOver, isActiveTarget, didDrop },
                index
              ) => {
                const isFirst = index === 0
                const isLast = index === foldersAndFiles.length - 1

                if (item.type === 'folder') {
                  const folder = item.item as ISubFolder
                  return (
                    <Folder
                      key={`folder-${folder.id}`}
                      size={50}
                      folder={folder}
                      isDragging={isDragging}
                      isOver={isOver}
                      isSelected={isSelected}
                      isActiveTarget={isActiveTarget}
                      isFirst={isFirst}
                      isLast={isLast}
                    />
                  )
                }

                if (item.type === 'file') {
                  const file = item.item as IFile

                  return (
                    <FileItem
                      key={`file-${file.id}`}
                      size={50}
                      file={file}
                      didDrop={didDrop}
                      isDragging={isDragging}
                      isSelected={isSelected}
                      isFirst={isFirst}
                      isLast={isLast}
                    />
                  )
                }

                return null
              }}
              onDrop={handleOnDrop}
              renderDrag={items => <DragLayer items={items} />}
            />
          </AnimatedContentLoader>
        </ItemListWrapper>
      </FileBrowserWrapper>
    </UploadDropzone>
  )
}

const DragLayer: React.FC<{ items: IDraggableItem[] }> = ({ items }) => {
  const totalItems = items.length
  const renderableItems = items.slice(0, 5)

  return (
    <StyledDragLayer>
      {renderableItems.reverse().map((item, index) => {
        if (item.type === 'folder') {
          const folder = item.item as ISubFolder
          return (
            <StyledDragItem
              key={`folder-${item.item.id}`}
              index={index}
              count={renderableItems.length}
            >
              <Folder
                size={50}
                folder={folder}
                isDragging={false}
                isOver={false}
                isSelected={false}
                isActiveTarget={false}
              />
            </StyledDragItem>
          )
        }

        if (item.type === 'file') {
          const file = item.item as IFile
          return (
            <StyledDragItem
              key={`file-${item.item.id}`}
              index={index}
              count={renderableItems.length}
            >
              <FileItem
                size={50}
                file={file}
                didDrop={false}
                isDragging={false}
                isSelected={false}
              />
            </StyledDragItem>
          )
        }

        return null
      })}
      <DragCount>{totalItems}</DragCount>
    </StyledDragLayer>
  )
}

const StyledDragLayer = styled.div`
  position: relative;
  max-width: 400px;
`

const StyledDragItem = styled(motion.div)<{ index: number; count: number }>`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1rem;
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.1);
  z-index: ${({ count, index }) => 10 + count - index};
  transform: translate(${({ index }) => index * 4}px, ${({ index }) => index * 4}px);
`

const DragCount = styled.div`
  background: ${({ theme }) => theme.colors.nocfoGreen};
  color: ${({ theme }) => theme.colors.neutralWhite};
  font-weight: bold;
  padding: ${({ theme }) => theme.spacing.xs}rem;
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  min-width: 1.6rem;
  height: 1.6rem;
  transform: translate(50%, 50%);
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  z-index: 20;
`

const FileBrowserWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
`

const ItemListWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`

const UpperWidget = styled.div`
  background: white;
  border-radius: 1rem;
  margin-bottom: ${({ theme }) => theme.spacing.xs}rem;
`

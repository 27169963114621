import React, { useCallback } from 'react'
import { Input, Select } from '@root/components'
import { FormProvider, get, useFormContext } from 'react-hook-form'
import {
  INVOICE_REFERENCE_METHODS,
  INVOICE_REPETITION_DAYS,
  INVOICE_REPETITION_MONTHS,
  INVOICE_REPETITION_OPTIONS,
  InvoiceRepetitionOptions
} from '@root/constants'
import { useTranslation } from 'react-i18next'
import { IInvoice } from '@root/query'
import dayjs from 'dayjs'
import { theme } from '@root/styles'
import styled from 'styled-components'
import SendInvoiceFormBase from '../SendInvoiceFormBase'

interface Props {
  invoiceReceiver: number
  toggleAutoOpen?: boolean
  isCreditNote: boolean
}

export const InvoiceFormRecurrenceSection: React.FC<Props> = ({
  invoiceReceiver,
  isCreditNote
}) => {
  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext<Partial<IInvoice>>()

  const methods = useFormContext()

  const [t] = useTranslation()

  const [repetitionType, repetitionMonth, invoicingDate] = watch([
    'recurrence_rule.rule.frequency',
    'recurrence_rule.rule.by_month',
    'invoicing_date'
  ])

  const generateDefaultValueForRecurrenceDate = (invoicingDate: string) => {
    return parseInt(invoicingDate?.split('-')[2])
  }

  const defaultValueForRecurrenceDate = generateDefaultValueForRecurrenceDate(invoicingDate)

  const dateValidation = useCallback(date => {
    if (date === '') {
      return true
    }

    const dateObj = dayjs(date, 'YYYY-MM-DD')
    if (!dateObj.isValid()) {
      return t('validation.invalidDate')
    }

    if (dateObj.isBefore(dayjs(new Date()))) {
      return t('validation.dateInFuture')
    }

    return true
  }, [])

  const showMonthSelector = repetitionType === InvoiceRepetitionOptions.YEARLY
  const showDaySelector =
    (repetitionType === InvoiceRepetitionOptions.YEARLY && repetitionMonth) ||
    repetitionType === InvoiceRepetitionOptions.MONTHLY

  const showRest = repetitionType !== InvoiceRepetitionOptions.NO_SELECTION
  const showDelivery = repetitionType !== InvoiceRepetitionOptions.NO_SELECTION

  return (
    <FormProvider {...methods}>
      {!isCreditNote && (
        <>
          {/* RECURRENCE OPTIONS */}
          <Select
            id="invoice-form-repetition-type"
            defaultValue={InvoiceRepetitionOptions.NO_SELECTION}
            label={t('invoicing.form.repetition.label')}
            info={t('invoicing.form.repetition.helperRepetition')}
            errors={get(errors, 'recurrence_rule.rule.frequency')}
            {...register('recurrence_rule.rule.frequency', { valueAsNumber: true })}
          >
            {INVOICE_REPETITION_OPTIONS.map((option, index) => (
              <option key={`invoice_repetition_option_${index}`} value={option.value}>
                {t(option.labelKey)}
              </option>
            ))}
          </Select>

          {(showDaySelector || showMonthSelector) && (
            <>
              <SectionWrapper>
                <StyledTitle>{t('invoicing.form.repetition.sectionTitle')}</StyledTitle>
                {showMonthSelector && (
                  <Select
                    id="invoice-form-repetition-month"
                    label={t('invoicing.form.repetition.month')}
                    info={t('invoicing.form.repetition.helperMonth')}
                    errors={get(errors, 'recurrence_rule.rule.by_month.0')}
                    required={showMonthSelector}
                    style={{ flex: '1 0 20%', marginRight: `${theme.spacing.xxs}rem` }}
                    {...register('recurrence_rule.rule.by_month.0', {
                      valueAsNumber: true,
                      shouldUnregister: true,
                      required: { value: true, message: t('validation.required') }
                    })}
                  >
                    <option hidden={true} value={null} />
                    {INVOICE_REPETITION_MONTHS.map((option, index) => (
                      <option key={`invoice_repetition_month_${index}`} value={option.value}>
                        {t(option.labelKey)}
                      </option>
                    ))}
                  </Select>
                )}

                {showDaySelector && (
                  <Select
                    id="invoice-form-repetition-day"
                    label={t('invoicing.form.repetition.day')}
                    info={t('invoicing.form.repetition.helperDay')}
                    required={showDaySelector}
                    errors={get(errors, 'recurrence_rule.rule.by_month_day.0')}
                    defaultValue={defaultValueForRecurrenceDate}
                    style={{
                      flex: showMonthSelector ? '1 0 30%' : '1 0 40%',
                      marginRight: `${theme.spacing.xxs}rem`
                    }}
                    {...register('recurrence_rule.rule.by_month_day.0', {
                      valueAsNumber: true,
                      shouldUnregister: true,
                      required: { value: true, message: t('validation.required') }
                    })}
                  >
                    {INVOICE_REPETITION_DAYS.map((option, index) => (
                      <option key={`invoice_repetition_day_${index}`} value={option.value}>
                        {option.labelKey ? t(option.labelKey) : option.value}
                      </option>
                    ))}
                  </Select>
                )}

                {/* LAST DAY OF REPETITION AND REFERENCE NUMBER */}
                {showRest && (
                  <>
                    <Input
                      label={t('invoicing.form.repetition.invoicingEnd')}
                      type="date"
                      info={t('invoicing.form.repetition.invoicingEndInfo')}
                      style={{ flex: '1 0 40%' }}
                      errors={get(errors, 'recurrence_end')}
                      {...register('recurrence_end', {
                        validate: dateValidation,
                        shouldUnregister: true
                      })}
                    />

                    <Select
                      id="invoice-form-reference-methods"
                      label={t('invoicing.form.reference')}
                      info={t('invoicing.form.repetition.referenceInfo')}
                      required={true}
                      errors={get(errors, 'recurrence_reference_method')}
                      {...register('recurrence_reference_method', {
                        shouldUnregister: true,
                        required: { value: true, message: t('validation.required') }
                      })}
                    >
                      <option hidden={true} value={null} />
                      {INVOICE_REFERENCE_METHODS.map((option, index) => (
                        <option
                          key={`invoice_repetition_reference_method_${index}`}
                          value={option.value}
                        >
                          {t(option.labelKey)}
                        </option>
                      ))}
                    </Select>
                  </>
                )}

                {/* DELIVERY METHODS */}
                {showDelivery && (
                  <>
                    <StyledTitle>{t('invoicing.form.repetition.headerSending')}</StyledTitle>
                    <SendInvoiceFormBase
                      invoiceReceiver={invoiceReceiver}
                      emailContentName={'recurrence_email_content'}
                      emailSubjectName={'recurrence_email_subject'}
                    />
                  </>
                )}
              </SectionWrapper>
            </>
          )}
        </>
      )}
    </FormProvider>
  )
}

const StyledTitle = styled.h3`
  flex: 1 0 100%;
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
`

const SectionWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.md}rem;
  border: 3px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
`

import React, { useState } from 'react'
import { IBalanceCheck } from '@query'
import styled from 'styled-components'
import { FaExclamationCircle } from 'react-icons/fa'
import {
  useFloating,
  useHover,
  shift,
  autoUpdate,
  flip,
  offset,
  FloatingPortal
} from '@floating-ui/react'
import { AnimatePresence } from 'framer-motion'
import { BalanceCheckDropdown } from '@pages/DocumentPage/BalanceCheck/BalanceCheckDropdown.tsx'
import { useTranslation } from 'react-i18next'

interface Props {
  result: IBalanceCheck
}

export const BalanceCheckResult: React.FC<Props> = ({ result }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const isInvalid = !!result?.first_invalid

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [shift(), offset(12), flip()],
    placement: 'bottom-start'
  })

  useHover(context, {
    delay: {
      open: 200,
      close: 400
    }
  })

  if (!isInvalid) return null

  return (
    <>
      <StyledBadge ref={refs.setReference}>
        <FaExclamationCircle /> <span>{t('document.balanceMarker.title')}</span>
        <div style={{ flex: 1 }} />
      </StyledBadge>

      <AnimatePresence>
        {isOpen && (
          <FloatingPortal>
            <BalanceCheckDropdown ref={refs.setFloating} styles={floatingStyles} result={result} />
          </FloatingPortal>
        )}
      </AnimatePresence>
    </>
  )
}

const StyledBadge = styled.span`
  background: ${({ theme }) => theme.colors.orange}22;
  color: ${({ theme }) => theme.colors.orange};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  padding: ${({ theme }) => theme.spacing.sm}rem;
  border-radius: 0.6rem;
  font-weight: 500;
  cursor: pointer;

  button {
    background: none;
    border: none;
    color: ${({ theme }) => theme.colors.orange};
    font-size: ${({ theme }) => theme.fontSize.sm}rem;
    font-weight: 500;
    cursor: pointer;
  }
`

import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  gap: ${({ theme }) => theme.spacing.sm}rem;

  & + & {
  margin-top: ${({ theme }) => theme.spacing.sm}rem;
`

export const ActionBarContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colors.neutralWhite};
  padding: ${({ theme }) => theme.spacing.md}rem;

  border-radius: 1rem 1rem 0 0;
  margin-bottom: 0;

  &.isLast {
    border-radius: 1rem;
    margin-bottom: 1rem;
  }
`

export const SecondaryActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`

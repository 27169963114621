import {
  Alert,
  Button,
  Input,
  MonetaryAmount,
  TextArea,
  ContactSelector,
  TextTooltip
} from '@components'
import {
  InvoiceRepetitionOptions,
  VatCode,
  getContactPageUrl,
  getInvoicingPageUrl
} from '@constants'
import { useBusinessContext } from '@context'
import { useCacheFormValues, useLocalStorage } from '@hooks'
import { createInvoice, fetchContact, IInvoice, IInvoiceRow, IProduct, updateInvoice } from '@query'
import { setAPIErrors } from '@utils'
import dayjs from 'dayjs'
import React, { useCallback, useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { FaQuestionCircle, FaSave, FaUndo } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { InvoiceRows } from './InvoiceRows'
import { InvoiceCopyDropdown } from './InvoiceCopyDropdown'
import { InvoiceFormMoreSection } from './InvoiceFormMoreSection'
import InvoiceFormRowToggle from './InvoiceFormRowToggleButton'
import { AttachmentSelector } from '@containers/AttachmentSelector'
import { InvoiceFormRecurrenceSection } from './InvoiceFormRecurrenceSection'

interface IAdvancedRows extends Partial<IInvoiceRow>, Partial<IProduct> {}

export const calculateInvoiceRowValues = (
  vat_rate: number,
  amount: number,
  product_count: number
) => {
  const vatExclusiveAmount = amount ? amount : 0

  const vatRate = vat_rate ? vat_rate : 0

  const net = vatExclusiveAmount * product_count
  const vat = (net * vatRate) / 100
  const tot = net + vat

  return {
    net,
    vat,
    tot
  }
}

export const DEFAULT_DATA_INVOICE_ROW: Partial<IInvoiceRow> = {
  vat_code: VatCode.TAX_FREE,
  vat_rate: 0,
  name: '',
  product_count: null,
  amount: 0,
  description: '',
  product: null,
  unit: ''
}

const useInvoiceTotalValues = (invoiceRows: IAdvancedRows[], isCreditNote: boolean) => {
  const readyRows = (invoiceRows || []).filter(row => {
    const hasProductCount = Boolean(row.product_count)
    return hasProductCount
  })

  const rowAmounts = readyRows.map(({ vat_rate, amount, product_count }) => {
    const { net, vat, tot } = calculateInvoiceRowValues(vat_rate, amount, product_count)

    return {
      net,
      vat,
      tot
    }
  })
  const totals = rowAmounts.reduce(
    (prev, curr) => ({
      net: prev.net + curr.net,
      vat: prev.vat + curr.vat,
      tot: prev.tot + curr.tot
    }),
    { net: 0, vat: 0, tot: 0 }
  )

  const _round = (value: number): number => Math.round(value * 100) / 100

  return {
    totals: {
      net: _round(totals.net) * (isCreditNote ? -1 : 1),
      vat: _round(totals.vat) * (isCreditNote ? -1 : 1),
      tot: _round(totals.tot) * (isCreditNote ? -1 : 1)
    }
  }
}

interface Props {
  editInvoiceId?: number
  defaultValues?: Partial<IInvoice>
  isCreditNoteFor?: number
  onSubmit: () => void
}

export const InvoiceForm: React.FC<Props> = ({
  editInvoiceId,
  defaultValues = {},
  isCreditNoteFor = null,
  onSubmit: _onSubmit
}) => {
  const {
    businessId,
    data: { can_invoice: canInvoice }
  } = useBusinessContext()

  const [advancedMode, setAdvancedMode] = useLocalStorage<boolean>(
    `${businessId}.invoicing.advancedInvoiceRowsEnabled`,
    false
  )
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const history = useHistory()
  const isEdit = !!editInvoiceId
  const _isCreditNoteFor = isCreditNoteFor || defaultValues.is_credit_note_for

  const setAdvancedModeCallback = useCallback(
    (value: boolean) => setAdvancedMode(value),
    [setAdvancedMode]
  )

  const getDefaultValues = useCallback(
    (data: Partial<IInvoice>): Partial<IInvoice> => ({
      invoicing_date: data?.invoicing_date || dayjs().format('YYYY-MM-DD'),
      receiver: data.receiver || null,
      payment_condition_days: data.payment_condition_days || 14,
      description: data.description,
      reference: data.reference,
      penalty_interest: data.penalty_interest || 7,
      is_credit_note_for: data.is_credit_note_for || _isCreditNoteFor,
      rows: (data.rows || [DEFAULT_DATA_INVOICE_ROW]) as IInvoiceRow[],
      is_active_recurrence_template: data?.is_active_recurrence_template || false,
      recurrence_rule: {
        start: data?.recurrence_rule?.start,
        rule: {
          frequency:
            data?.recurrence_rule?.rule?.frequency || InvoiceRepetitionOptions.NO_SELECTION,
          by_month_day: data?.recurrence_rule?.rule?.by_month_day,
          by_month: data?.recurrence_rule?.rule?.by_month
        }
      },
      recurrence_end: data?.recurrence_end || '',
      recurrence_parent_id: data?.recurrence_parent_id,
      recurrence_reference_method: data?.recurrence_reference_method,
      recurrence_email_subject: data?.recurrence_email_subject,
      recurrence_email_content: data?.recurrence_email_content,
      next_recurrences: data?.next_recurrences,
      attachments: data?.attachments
    }),
    [defaultValues]
  )

  const methods = useForm<Partial<IInvoice>>({
    defaultValues: getDefaultValues(defaultValues)
  })
  const {
    register,
    control,
    watch,
    handleSubmit,
    setError,
    reset,
    getValues,
    setValue,
    formState: { errors, isSubmitting }
  } = methods

  // PERSISTING
  // ==========

  const receiver = watch('receiver')
  const invoicingDate = watch('invoicing_date')
  const paymentCondition = watch('payment_condition_days')
  const invoiceRows = watch('rows')

  const isCreditNote = Boolean(_isCreditNoteFor)
  const { totals } = useInvoiceTotalValues(invoiceRows, isCreditNote)

  const { resetCache } = useCacheFormValues<Partial<IInvoice>>({
    cacheKey: `${businessId}.invoiceForm.new.${dayjs().format('YYYY-MM-DD')}`, // The cached values are only stored for that day
    getValues,
    setValue,
    enabled: !isEdit && !isCreditNote
  })

  const { data: receiverData } = useQuery(
    [businessId, 'contact', receiver],
    () => fetchContact({ businessId, contactId: receiver }),
    { enabled: !!receiver }
  )

  const receiverCantBeInvoiced = receiverData?.can_be_invoiced === false

  const dueDate = useMemo(() => {
    return dayjs(invoicingDate).add(paymentCondition, 'day').format('YYYY-MM-DD')
  }, [invoicingDate, paymentCondition])

  const resetForm = useCallback(() => {
    reset(undefined, { keepDefaultValues: true })
    resetCache()
  }, [])

  const options = {
    onError: ({ status, data }) => {
      if (status === 400) {
        setAPIErrors(data, setError)
      }
    },
    onSuccess: async data => {
      const url = getInvoicingPageUrl(businessId, { id: data.id, type: 'sales' })
      history.push(url)

      await Promise.all([
        queryClient.invalidateQueries([businessId, 'invoices']),
        queryClient.invalidateQueries([businessId, 'combined_invoices'])
      ])

      resetForm()
    }
  }

  const updateMutation = useMutation<unknown, unknown, Partial<IInvoice>>(
    data => updateInvoice({ businessId, invoiceId: editInvoiceId }, data),
    options
  )
  const createMutation = useMutation<unknown, unknown, Partial<IInvoice>>(
    data => createInvoice({ businessId }, data),
    options
  )

  const setRecurrenceRuleEdgeCases = (data: Partial<IInvoice>) => {
    // Needs to be set here
    data.is_active_recurrence_template = true

    // For now this is the default value from frontend
    data?.recurrence_rule && (data.recurrence_rule.start = null)

    // If date picker has not been used, the recurrence will not have ending date
    data?.recurrence_end === '' && (data.recurrence_end = null)
  }

  const clearRecurrenceFields = (data: Partial<IInvoice>) => {
    data.is_active_recurrence_template = false
    data.recurrence_rule = null
    data.recurrence_end = null
    data.recurrence_email_content = null
    data.recurrence_email_subject = null
    data.delivery_method = null
    data.recurrence_reference_method = null
  }

  const onSubmit = handleSubmit(async data => {
    try {
      if (
        !data?.recurrence_rule ||
        data?.recurrence_rule?.rule?.frequency === InvoiceRepetitionOptions.NO_SELECTION
      ) {
        clearRecurrenceFields(data)
      } else {
        setRecurrenceRuleEdgeCases(data)
      }

      // https://github.com/orgs/react-hook-form/discussions/9879#discussioncomment-4883381
      const promise = isEdit ? updateMutation.mutateAsync(data) : createMutation.mutateAsync(data)
      await promise
      _onSubmit()
    } catch {
      return null
    }
  })

  const copyInvoiceData = (invoice: IInvoice) => {
    methods.setValue('receiver', invoice.receiver)
    methods.setValue('payment_condition_days', invoice.payment_condition_days)
    methods.setValue('description', invoice.description)
    methods.setValue('penalty_interest', invoice.penalty_interest)
    methods.setValue('rows', invoice.rows)
  }

  return (
    <FormProvider {...methods}>
      <FormWrapper>
        <form id="invoice-form" onSubmit={onSubmit}>
          <Alert
            isVisible={!canInvoice}
            type="warning"
            title={t('invoicing.form.cannotInvoiceTitle')}
            description={t('invoicing.form.cannotInvoiceDescription')}
          />

          <Alert
            title={t('invoicing.form.missingInvoicingAddressTitle')}
            type="warning"
            isVisible={receiverCantBeInvoiced}
            description={
              <Trans i18nKey={'invoicing.form.missingInvoicingAddress'}>
                <Link to={getContactPageUrl(businessId, { id: receiverData?.id })} />
              </Trans>
            }
          />

          <FormGroup>
            <Input
              data-test="invoicing-form-date-input"
              label={t('invoicing.form.invoiceDate')}
              required={true}
              type="date"
              errors={errors?.invoicing_date}
              {...register('invoicing_date', {
                required: { value: true, message: t('validation.required') }
              })}
              info={t('invoicing.form.invoiceDateInfo')}
            />

            {!_isCreditNoteFor && (
              <InvoiceCopyDropdown
                key="invoice-copy-dropdown"
                label={t('document.description')}
                placeholder={t('document.descriptionPlaceholder')}
                info={t('document.descriptionInfo')}
                id="invoice-description"
                businessId={businessId}
                required={true}
                copyInvoiceData={copyInvoiceData}
              />
            )}

            <Controller
              name="receiver"
              control={control}
              rules={{ required: { value: true, message: t('validation.required') } }}
              render={({ field, fieldState: { error } }) => (
                <ContactSelector
                  noDataMessage={t('contacts.contactSelector.noData')}
                  placeholder={t('contacts.contactSelector.placeholder')}
                  label={t('invoicing.form.receiver')}
                  {...field}
                  errors={error}
                  ref={null}
                  clearSelectedItem={() => setValue('receiver', undefined)}
                  required={true}
                  fetchOnlyInvoicableContacts={true}
                ></ContactSelector>
              )}
            />

            <InputRow>
              <Input
                label={t('invoicing.form.paymentCondition')}
                required={true}
                type="number"
                info={t('invoicing.form.paymentConditionInfo')}
                errors={errors?.payment_condition_days}
                {...register('payment_condition_days', {
                  valueAsNumber: true,
                  required: { value: true, message: t('validation.required') }
                })}
              />
              <Input
                disabled
                label={t('invoicing.form.dueDate')}
                type="date"
                info={t('invoicing.form.dueDateInfo')}
                value={dueDate}
              />
            </InputRow>

            <InvoiceFormRecurrenceSection
              invoiceReceiver={receiver}
              toggleAutoOpen={isEdit && defaultValues?.is_active_recurrence_template}
              isCreditNote={!!isCreditNoteFor}
            ></InvoiceFormRecurrenceSection>
          </FormGroup>

          <TextArea
            label={t('invoicing.form.description')}
            info={t('invoicing.form.descriptionInfo')}
            errors={errors?.description}
            {...register('description')}
          ></TextArea>

          {/* INTEREST AND REFERENCE */}
          <InvoiceFormMoreSection
            invoiceReceiver={receiver}
            toggleAutoOpen={isEdit && defaultValues?.is_active_recurrence_template}
            isCreditNote={!!isCreditNoteFor}
          ></InvoiceFormMoreSection>

          <InvoiceRowsActionBar>
            <SubHeader>{t('invoicing.form.rows.title')}</SubHeader>
            <InvoiceFormRowToggle
              advancedMode={advancedMode}
              setAdvancedMode={setAdvancedModeCallback}
            ></InvoiceFormRowToggle>
          </InvoiceRowsActionBar>

          <InvoiceRows control={control} isAdvanced={advancedMode} />

          <SubHeader>
            <span>{t('invoicing.form.attachments')}</span>
            <TextTooltip tooltip={t('invoicing.form.attachmentsInfo')}>
              <FaQuestionCircle />
            </TextTooltip>
          </SubHeader>
          <Controller
            name="attachments"
            control={control}
            render={({ field: { value, onChange } }) => (
              <AttachmentSelector
                value={value}
                onChange={onChange}
                showRemoveInsteadOfViewLink={true}
              />
            )}
          />
        </form>

        <InvoiceFooterWrapper>
          <Alert
            isVisible={Boolean((errors as any)?.general?.message)}
            type="error"
            description={(errors as any)?.general?.message}
          />

          <InvoiceFooterContent>
            <InvoiceFooterCol>
              <InvoiceRowColHeader>{t('invoicing.form.totals.net')}</InvoiceRowColHeader>
              <InvoiceRowColValue>{<MonetaryAmount value={totals.net} />}</InvoiceRowColValue>
            </InvoiceFooterCol>

            <InvoiceFooterCol>
              <InvoiceRowColHeader>{t('invoicing.form.totals.vat')}</InvoiceRowColHeader>
              <InvoiceRowColValue>{<MonetaryAmount value={totals.vat} />}</InvoiceRowColValue>
            </InvoiceFooterCol>

            <InvoiceFooterCol>
              <InvoiceRowColHeader>{t('invoicing.form.totals.sum')}</InvoiceRowColHeader>
              <InvoiceRowColValue>{<MonetaryAmount value={totals.tot} />}</InvoiceRowColValue>
            </InvoiceFooterCol>
          </InvoiceFooterContent>

          <FooterButtonContainer>
            <Button data-test="cancel-invoicee-button" onClick={() => _onSubmit()}>
              {t('general.cancel')}
            </Button>
            <Button icon={<FaUndo />} onClick={() => resetForm()} tabIndex={-1}>
              {t('general.startOver')}
            </Button>
            <div style={{ flex: 1 }} />
            <Button
              data-test="submit-invoice-button"
              icon={<FaSave />}
              form="invoice-form"
              intent="success"
              showSpinner={isSubmitting}
              disabled={!canInvoice || isSubmitting || receiverCantBeInvoiced}
            >
              {t('invoicing.form.save')}
            </Button>
          </FooterButtonContainer>
        </InvoiceFooterWrapper>
      </FormWrapper>
    </FormProvider>
  )
}

const InputRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`

const FormGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg}rem;
`

const SubHeader = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  margin-top: ${({ theme }) => theme.spacing.md}rem;
  margin-bottom: ${({ theme }) => theme.spacing.sm}rem;

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}rem;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  form {
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 1rem;
    flex: 1;
  }
`

const InvoiceFooterWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.lg}rem;
  border-top: 1px solid ${({ theme }) => theme.colors.neutralGray};
`

const InvoiceFooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`

const InvoiceFooterCol = styled.div``

const InvoiceRowColHeader = styled.div`
  font-weight: bold;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.metalGray};
`

const InvoiceRowColValue = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  font-variant-numeric: tabular-nums;
`

const FooterButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing.md}rem;
  gap: ${({ theme }) => theme.spacing.xs}rem;
`
const InvoiceRowsActionBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  svg {
    color: ${({ theme }) => theme.colors.metalGray};
  }
`

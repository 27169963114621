import { Progress } from '@components'
import { useTheme } from '@hooks'
import { Card, Heading, Pane, Text } from 'evergreen-ui'
import React, { useCallback } from 'react'
import { formatDate } from '@utils'

interface Props {
  header?: React.ReactElement
  titleFrom: string
  dateFrom: Date
  titleTo: string
  dateTo: Date
  status?: string
  progressColor?: (percent: number) => string
}

export const DateProgressItem: React.FC<Props> = ({
  header,
  titleFrom,
  titleTo,
  dateFrom,
  dateTo,
  status = 'default',
  progressColor
}) => {
  const theme = useTheme()

  const getColorByPercent = useCallback((percent: number): string => {
    if (status === 'error') {
      return theme.colors.nocfoRed
    }

    if (status === 'warning') {
      return theme.colors.nocfoYellow
    }

    if (percent < 100) {
      return theme.colors.nocfoBlue
    }

    return theme.colors.nocfoGreen
  }, [])

  const getPercent = useCallback(() => {
    const start = new Date(dateFrom).setHours(0, 0, 0, 0)
    const end = new Date(dateTo).setHours(23, 59, 59, 999)
    const now = Date.now()

    return Math.max(Math.min(Math.floor(((now - start) / (end - start)) * 100), 100), 0)
  }, [dateFrom, dateTo])

  return (
    <Card
      display="flex"
      flex="1"
      position="relative"
      flexDirection="column"
      padding={16}
      borderRadius="1rem"
      maxWidth="100%"
    >
      {header && (
        <Pane marginBottom={4} display="flex" justifyContent="flex-end">
          {header}
        </Pane>
      )}
      <Pane display="flex" width="100%">
        <Pane flexDirection="column" display="flex" flex={1}>
          <Pane display="flex">
            <Pane flex={1}>
              <Heading size={100}>{titleFrom}</Heading>
              <Text>{formatDate(dateFrom)}</Text>
            </Pane>
            <Pane display="flex" flexDirection="column">
              <Heading alignSelf="flex-end" size={100}>
                {titleTo}
              </Heading>
              <Text alignSelf="flex-end">{formatDate(dateTo)}</Text>
            </Pane>
          </Pane>
          <Pane marginTop="0.4rem">
            <Progress
              percent={getPercent()}
              progressColor={progressColor?.(getPercent()) ?? getColorByPercent(getPercent())}
            />
          </Pane>
        </Pane>
      </Pane>
    </Card>
  )
}

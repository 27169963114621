import { getPeriodPageUrl } from '@constants'
import { useBusinessContext } from '@context'
import { IPeriod } from '@query'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { DateProgressItem } from '../../components/DateProgressItem'

interface Props {
  period: IPeriod
  isSelected: boolean
  isFirstOfType: boolean
  isLastOfType: boolean
  height: number
}

export const PeriodListItem: React.FC<Props> = ({
  period,
  isSelected,
  isFirstOfType,
  isLastOfType,
  height
}) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()

  const itemUrl = useMemo(
    () =>
      getPeriodPageUrl(businessId, {
        id: period.id
      }),
    [period.id]
  )

  return (
    <StyledItemWrapper
      itemHeight={height}
      isFirstOfType={isFirstOfType}
      isLastOfType={isLastOfType}
      isSelected={isSelected}
      data-test="accounting-period-list-item"
    >
      <Link to={itemUrl} style={{ textDecoration: 'none' }}>
        <DateProgressItem
          dateFrom={new Date(period.start_date)}
          dateTo={new Date(period.end_date)}
          titleFrom={t('period.form.startDate')}
          titleTo={t('period.form.endDate')}
          status={'default'}
        />
      </Link>
    </StyledItemWrapper>
  )
}

const StyledItemWrapper = styled.div<{
  isSelected: boolean
  itemHeight: number
  isFirstOfType: boolean
  isLastOfType: boolean
}>`
  cursor: pointer;

  background: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.defaultHover : theme.colors.neutralWhite};
  height: ${({ itemHeight }) => itemHeight}px;
  border-top-left-radius: ${({ isFirstOfType }) => (isFirstOfType ? 1 : 0)}rem;
  border-top-right-radius: ${({ isFirstOfType }) => (isFirstOfType ? 1 : 0)}rem;
  border-bottom-right-radius: ${({ isLastOfType }) => (isLastOfType ? 1 : 0)}rem;
  border-bottom-left-radius: ${({ isLastOfType }) => (isLastOfType ? 1 : 0)}rem;

  &:hover {
    background: ${({ theme }) => theme.colors.defaultHover};
  }
`

import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { FaCheck, FaExclamationTriangle } from 'react-icons/fa'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from 'react-i18next'
import { formatCurrency, formatDate } from '@utils'
import { IBalanceCheck } from '@query'
import { CSSProperties, forwardRef } from 'react'
import cn from 'classnames'

interface Props {
  ref: React.ForwardedRef<any>
  styles: CSSProperties
  result: IBalanceCheck
}

export const BalanceCheckDropdown: React.FC<Props> = forwardRef(({ styles, result }, ref) => {
  const { t } = useTranslation()
  const invalidResult = result?.first_invalid
  const isInvalid = !!invalidResult
  if (!invalidResult) return null // If there is no invalid result, don't show the dropdown

  const accountName = invalidResult
    ? [invalidResult.account.number, invalidResult.account.name].join(' ')
    : null

  return (
    <StyledDropdown
      ref={ref}
      style={styles}
      className={cn({
        success: !isInvalid,
        warning: isInvalid
      })}
    >
      <h4>
        {isInvalid ? <FaExclamationTriangle /> : <FaCheck />}
        <span>{t('document.balanceMarker.title')}</span>
      </h4>
      <p>{t('document.balanceMarker.description')}</p>
      <p>
        <ReactMarkdown>
          {t('document.balanceMarker.accountDetails', {
            accountName,
            date: formatDate(invalidResult?.date_at),
            expectedBalance: formatCurrency(invalidResult?.expected_balance),
            actualBalance: formatCurrency(invalidResult?.actual_balance)
          })}
        </ReactMarkdown>
      </p>
      <p>
        <ReactMarkdown>
          {t('document.balanceMarker.lastValid', {
            lastValidDate: formatDate(result?.last_valid?.date_at)
          })}
        </ReactMarkdown>
      </p>
    </StyledDropdown>
  )
})

const StyledDropdown = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  position: fixed;
  border-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.md}rem;
  max-width: 400px;
  width: 100vw;
  background: ${({ theme }) => theme.colors.menuBg};

  &.success {
    color: ${({ theme }) => theme.colors.neutralWhite};

    svg {
      fill: ${({ theme }) => theme.colors.nocfoGreen};
    }
  }

  &.warning {
    color: ${({ theme }) => theme.colors.neutralWhite};

    svg {
      fill: ${({ theme }) => theme.colors.nocfoYellow};
    }
  }

  h4 {
    font-size: ${({ theme }) => theme.fontSize.md}rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs}rem;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`

import styled from 'styled-components'

import { theme } from '@root/styles'
import { FaMask, FaCalculator, FaDotCircle, FaTimes } from 'react-icons/fa'
import { FaClockRotateLeft, FaRotate } from 'react-icons/fa6'
import { useBusinessContext } from '@root/context'
import { DashboardAlertTypes, IDashboardAlert, DashboardAlertIntents } from '@root/query'
import { getInvoicingPageUrl, getSettingsPageUrl, getVATPageUrl } from '@root/constants'
import { Link } from 'react-router-dom'
import ifInsuranceImgSrc from '@assets/if-insurance.svg'
import cn from 'classnames'
import loadingImg from '@assets/undraw/undraw_nature_yf30.svg'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@components'

const variantToColor = {
  [DashboardAlertIntents.ERROR]: theme.colors.nocfoRed,
  [DashboardAlertIntents.SUGGESTION]: theme.colors.nocfoPurple,
  [DashboardAlertIntents.INFO]: theme.colors.nocfoBlue,
  [DashboardAlertIntents.WARNING]: theme.colors.nocfoYellow,
  [DashboardAlertIntents.SUCCESS]: theme.colors.nocfoGreen
}

const getColor = (type, intent) => {
  if (
    type === DashboardAlertTypes.IF_INSURANCE_NEW_BUSINESS ||
    type === DashboardAlertTypes.IF_INSURANCE_TENDERING
  ) {
    return '#0054f0' // If insurance blue
  }
  return variantToColor[intent] || theme.colors.nocfoBlue
}

const getBackgroundColor = (type, intent) => {
  if (
    type === DashboardAlertTypes.IF_INSURANCE_NEW_BUSINESS ||
    type === DashboardAlertTypes.IF_INSURANCE_TENDERING
  ) {
    return '#fff' // If insurance blue
  }
  return `${variantToColor[intent] || theme.colors.nocfoBlue}44`
}

const getIsSponsored = type => {
  return (
    type === DashboardAlertTypes.IF_INSURANCE_NEW_BUSINESS ||
    type === DashboardAlertTypes.IF_INSURANCE_TENDERING
  )
}

const getIsDismissable = type => {
  return (
    type === DashboardAlertTypes.IF_INSURANCE_NEW_BUSINESS ||
    type === DashboardAlertTypes.IF_INSURANCE_TENDERING
  )
}

const typeToIcon = {
  [DashboardAlertTypes.BANK_INTEGRATION_CREATION]: <FaRotate />,
  [DashboardAlertTypes.BANK_INTEGRATION_RENEWAL]: <FaClockRotateLeft />,
  [DashboardAlertTypes.VAT_PERIOD_REPORTING_PENDING]: <FaMask />,
  [DashboardAlertTypes.UNPAID_PURCHASE_INVOICES]: <FaCalculator />,
  [DashboardAlertTypes.UNPAID_SALES_INVOICES]: <FaCalculator />,
  [DashboardAlertTypes.IF_INSURANCE_NEW_BUSINESS]: <img src={ifInsuranceImgSrc} />,
  [DashboardAlertTypes.IF_INSURANCE_TENDERING]: <img src={ifInsuranceImgSrc} />
}

export interface IAlertCard {
  alert: IDashboardAlert
  handleOnDismiss?: () => void
}

const AlertCard: React.FC<IAlertCard> = ({
  alert: { title, message, intent, type, link, metadata },
  handleOnDismiss
}) => {
  const isSponsored = getIsSponsored(type)
  const color = getColor(type, intent)
  const bgColor = getBackgroundColor(type, intent)
  const icon = typeToIcon[type] || <FaDotCircle />
  const isDismissable = getIsDismissable(type)
  const { businessId } = useBusinessContext()

  const getLinkURL = () => {
    if (link !== undefined && link !== null) {
      return link
    }

    let linkToRedirect = undefined

    switch (type) {
      case DashboardAlertTypes.BANK_INTEGRATION_CREATION:
        linkToRedirect = getSettingsPageUrl(businessId, { page: 'integrations' })
        break
      case DashboardAlertTypes.VAT_PERIOD_REPORTING_PENDING:
        linkToRedirect = getVATPageUrl(businessId, { id: metadata?.vat_period_id })
        break
      case DashboardAlertTypes.BANK_INTEGRATION_RENEWAL:
        linkToRedirect = getSettingsPageUrl(businessId, { page: 'integrations' })
        break
      case DashboardAlertTypes.UNPAID_SALES_INVOICES:
        linkToRedirect = getInvoicingPageUrl(businessId)
        break
      case DashboardAlertTypes.UNPAID_PURCHASE_INVOICES:
        linkToRedirect = getInvoicingPageUrl(businessId)
        break
      default:
        break
    }

    if (linkToRedirect !== undefined) return linkToRedirect

    return `/${businessId}`
  }

  return (
    <AlertLink link={getLinkURL()}>
      <CardInnerWrapper
        tabIndex={-1}
        className={cn({ isSponsored })}
        color={color}
        bgColor={bgColor}
      >
        <HeaderWrapper color={color}>
          <IconWrapper color={color}>{icon}</IconWrapper>
          <Header color={color}>
            <span>{title}</span>
            {isDismissable && (
              <Button
                isSecondary={true}
                size="sm"
                icon={<FaTimes />}
                onClick={e => {
                  handleOnDismiss?.()
                  e.stopPropagation()
                  e.preventDefault()
                }}
              />
            )}
          </Header>
        </HeaderWrapper>
        <Content color={color}>{message}</Content>
      </CardInnerWrapper>
    </AlertLink>
  )
}

export const EmptyAlertCard: React.FC = () => {
  const { t } = useTranslation()
  return (
    <EmptyCardWrapper>
      <img src={loadingImg} />
      <div>
        <h4>{t('dashboard.emptyAlert.title')}</h4>
        <p>{t('dashboard.emptyAlert.description')}</p>
      </div>
    </EmptyCardWrapper>
  )
}

const AlertLink: React.FC<{ link: string; children: React.ReactNode }> = ({ link, children }) => {
  const isExternal = link.startsWith('http')

  return isExternal ? (
    <a href={link} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    <Link to={link} style={{ textDecoration: 'none' }}>
      {children}
    </Link>
  )
}

export default AlertCard

const CardInnerWrapper = styled.div<{ color: string; bgColor: string }>`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.sm}rem;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  border-radius: 1rem;

  height: 100%;
  width: 300px;

  background-color: ${props => props.bgColor};

  &.isSponsored {
    border: 3px solid ${props => props.color};
  }
`

const IconWrapper = styled.div`
  & > * {
    width: ${({ theme }) => theme.iconSize.sm}rem;
    height: ${({ theme }) => theme.iconSize.sm}rem;
    color: ${props => props.color};
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  width: 100%;

  .dismiss-icon {
    fill: ${props => props.color};
  }
`

const Header = styled.h4`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  margin: 0;
  color: ${props => props.color};
  font-weight: 600;
  white-space: pre-wrap;
  word-break: break-word;
  flex: 1;
`

const Content = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  white-space: pre-wrap;
  word-break: break-word;
  color: ${({ theme }) => theme.colors.metalGray};
`

const EmptyCardWrapper = styled.div`
  min-height: 100%;
  max-height: 100%;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;

  gap: ${({ theme }) => theme.spacing.lg}rem;
  padding: ${({ theme }) => theme.spacing.lg}rem ${({ theme }) => theme.spacing.xl}rem;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.neutralWhite};

  img {
    opacity: 0.9;
    height: 100%;
  }

  h4 {
    font-size: ${({ theme }) => theme.fontSize.lg}rem;
    margin-bottom: ${({ theme }) => theme.spacing.xxs}rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutralBlack};
  }

  p {
    color: ${({ theme }) => theme.colors.metalGray};
    margin: 0;
  }
`

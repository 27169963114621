import imgSrc from '@assets/undraw/undraw_online_connection_6778.svg'
import { Button } from '@components'
import {
  RadioGroup,
  RadioItem,
  SlideContent,
  SlideFooter,
  SlideImg,
  SlideText,
  SlideTitle,
  Spacer
} from '@pages/BankIntegrationOnboardingPage/slides/slide.styled'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaArrowRight } from 'react-icons/fa'

interface Props {
  onHasBankAccount: () => void
  onHasNoBankAccount: () => void
  onSkip: () => void
}

export const EnsureBankAccountSlide: React.FC<Props> = ({
  onHasBankAccount,
  onHasNoBankAccount,
  onSkip
}) => {
  const { t } = useTranslation()
  const [hasBankAccount, setHasBankAccount] = useState<boolean>(null)

  return (
    <>
      <SlideContent>
        <SlideTitle>{t('bankOnboarding.start.title')}</SlideTitle>

        <Spacer />

        <SlideImg src={imgSrc} />

        <Spacer />

        <SlideText>
          <p>{t('bankOnboarding.start.description')}</p>
          <p>
            <Trans i18nKey="bankOnboarding.start.description2">
              <a
                href={t('bankOnboarding.start.description2Link')}
                target="_blank"
                rel="noreferrer"
              />
            </Trans>
          </p>
        </SlideText>

        <SlideText>
          <b>{t('bankOnboarding.start.questionTitle')}</b>
          <RadioGroup>
            <RadioItem
              selected={hasBankAccount === true}
              onSelect={() => setHasBankAccount(true)}
              value={true}
              label={t('general.yes')}
            ></RadioItem>
            <RadioItem
              selected={hasBankAccount === false}
              onSelect={() => setHasBankAccount(false)}
              value={false}
              label={t('general.no')}
            ></RadioItem>
          </RadioGroup>
        </SlideText>
      </SlideContent>
      <SlideFooter>
        <Button
          intent="default"
          isSecondary
          onClick={() => onSkip()}
          data-test="skip-ensure-bank-account-button"
        >
          {t('general.skip')}
        </Button>
        <Spacer />
        <Button
          intent={'primary'}
          disabled={hasBankAccount === null}
          iconRight={<FaArrowRight />}
          onClick={() => {
            hasBankAccount ? onHasBankAccount() : onHasNoBankAccount()
          }}
        >
          {t('general.next')}
        </Button>
      </SlideFooter>
    </>
  )
}

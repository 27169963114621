import React, { createContext, ReactNode, useContext, useCallback } from 'react'
import { useLocalStorage } from '@hooks'
import { useBusinessContext } from '@context'

interface JobContextType {
  trackedJobs: string[]
  track: (jobId: string) => void
  untrack: (jobId: string) => void
}

export const JobContext = createContext<JobContextType | undefined>(undefined)

interface JobProviderProps {
  children: ReactNode
}

export const ImportJobProvider: React.FC<JobProviderProps> = ({ children }) => {
  const { businessId } = useBusinessContext()
  const [trackedJobs, setTrackedJobs] = useLocalStorage<string[]>(
    `${businessId}.importJobs.ids`,
    []
  )

  const track = useCallback((jobId: string) => setTrackedJobs(prevJobs => [...prevJobs, jobId]), [])
  const untrack = useCallback(
    (jobId: string) => setTrackedJobs(prevJobs => prevJobs.filter(id => id !== jobId)),
    []
  )

  return (
    <JobContext.Provider
      value={{
        trackedJobs: trackedJobs || [],
        track,
        untrack
      }}
    >
      {children}
    </JobContext.Provider>
  )
}

export const useJobContext = (): JobContextType => useContext(JobContext)

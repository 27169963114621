import { AnimatedContentLoader, DoubleColumnLayout, DoubleColumnLayoutColumn } from '@components'
import { useBusinessContext } from '@context'
import { useLocalStorage, useQueryParam } from '@hooks'
import { collectFromPages, fetchAccounts, IAccount } from '@query'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery } from 'react-query'
import styled from 'styled-components'
import { AccountActionBar, AccountDetails } from '../../components'
import { AccountList } from './AccountList'
import { IFilter } from '@root/components/ActionBar/FilterBar/FilterSelection'

export const NormalAccountPage: React.FC = () => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const [accountId, setAccountId] = useQueryParam<number>('id', value => parseInt(value, 10))
  const [search, setSearch] = useState('')
  const [filters, setFilter] = useLocalStorage<IFilter[]>(`${businessId}.filters.account`, [])
  const { data, isFetched, hasNextPage, fetchNextPage } = useInfiniteQuery(
    [businessId, 'accounts', { search, filters }],
    ({ pageParam = 1 }) =>
      fetchAccounts({ businessId }, {
        search,
        ...filters?.reduce(
          (prev, current) => ({
            ...prev,
            ...current
          }),
          {}
        ),
        is_shown: true,
        page: pageParam,
        page_size: 100
      } as any),
    {
      getNextPageParam: lastPage => lastPage.next,
      getPreviousPageParam: lastPage => lastPage.prev,
      keepPreviousData: true
    }
  )
  const accounts = collectFromPages<IAccount>(data)

  return (
    <DoubleColumnLayout
      header={t('accounts.title')}
      isRightVisible={Boolean(accountId)}
      onRightClose={() => setAccountId(undefined)}
    >
      <DoubleColumnLayoutColumn>
        <PageWrapper>
          <AccountActionBar
            onSearch={search => setSearch(search)}
            onFilter={filters => setFilter(filters)}
            activeFilters={filters}
          />

          <AccountListWrapper>
            <AnimatedContentLoader
              isLoading={!isFetched}
              isEmpty={accounts.length === 0}
              isEmptyDescription={t('accounts.empty')}
            >
              <AccountList
                accounts={accounts}
                onScrollBottom={() => hasNextPage && fetchNextPage()}
              />
            </AnimatedContentLoader>
          </AccountListWrapper>
        </PageWrapper>
      </DoubleColumnLayoutColumn>

      <DoubleColumnLayoutColumn isRight={true} innerKey={`account-${accountId}`}>
        {accountId && <AccountDetails accountId={accountId} />}
      </DoubleColumnLayoutColumn>
    </DoubleColumnLayout>
  )
}

const PageWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`

const AccountListWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`

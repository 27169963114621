import React from 'react'
import { IBalanceCheck } from '@query'
import { FaExclamation } from 'react-icons/fa'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import {
  FloatingPortal,
  useFloating,
  useHover,
  offset,
  flip,
  autoUpdate,
  shift
} from '@floating-ui/react'
import { BalanceCheckDropdown } from '@pages/DocumentPage/BalanceCheck/BalanceCheckDropdown.tsx'

interface BalanceMarkerProps {
  result: IBalanceCheck
}

const MARKER_ICON_SIZE = 12

export const BalanceMarker: React.FC<BalanceMarkerProps> = ({ result }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const invalidResult = result?.first_invalid

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [shift(), offset(MARKER_ICON_SIZE), flip()],
    placement: 'bottom'
  })

  useHover(context, {
    delay: {
      open: 200,
      close: 400
    }
  })

  if (!invalidResult) return null

  return (
    <MarkerWrapper>
      <StyledMarker id={'balance-marker'} ref={refs.setReference}>
        <FaExclamation />
      </StyledMarker>

      <AnimatePresence>
        {isOpen && (
          <FloatingPortal>
            <BalanceCheckDropdown ref={refs.setFloating} styles={floatingStyles} result={result} />
          </FloatingPortal>
        )}
      </AnimatePresence>
    </MarkerWrapper>
  )
}

const MarkerWrapper = styled.div`
  height: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-50%);
  left: 50%;
  bottom: 0;
  z-index: 10;
  width: 0;
`

const StyledMarker = styled(motion.div)`
  position: relative;
  z-index: 10;
  cursor: pointer;
  transition: 0.4s;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xxs}rem;
  background: ${({ theme }) => theme.colors.orange};
  padding: ${({ theme }) => theme.spacing.xxs}rem ${({ theme }) => theme.spacing.xs}rem;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutralWhite};
  border-radius: 0.4rem;
  border: 2px solid ${({ theme }) => theme.colors.neutralWhite};

  & > svg {
    width: ${MARKER_ICON_SIZE}px;
    height: ${MARKER_ICON_SIZE}px;
    fill: ${({ theme }) => theme.colors.neutralWhite};
  }
`

import { MonetaryAmount, NotificationIcon } from '@components'
import { getDocumentsPageUrl } from '@constants'
import { useQueryParams, useTheme } from '@hooks'
import { IBalanceCheck, IDocument } from '@query'
import { documentHasAttachment, formatDate, selectUserNotificationIcon } from '@utils'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { FaFlag, FaLock, FaPaperclip } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { DraftBadge } from './DraftBadge'
import { BalanceMarker } from '@pages/DocumentPage/BalanceCheck/BalanceMarker.tsx'

interface DocumentListItemProps {
  document: IDocument
  isSelected: boolean
  businessId: string
  isLastOfType: boolean
  isFirstOfType: boolean
  balanceResult?: IBalanceCheck
}

export const DocumentListItem: React.FC<DocumentListItemProps> = ({
  document,
  businessId,
  isSelected,
  isFirstOfType,
  isLastOfType,
  balanceResult
}) => {
  const documentRef = React.useRef(null)
  const theme = useTheme()
  const [params] = useQueryParams()

  const Icon = React.memo<{ isFlagged: boolean; isLocked: boolean; hasAttachments: boolean }>(
    ({ isFlagged, isLocked, hasAttachments }) => {
      const commonProps = {
        size: 12,
        color: theme.colors.metalGray
      }

      if (isFlagged) {
        return <FaFlag {...commonProps} color={theme.colors.nocfoRed} />
      }

      if (isLocked) {
        return <FaLock {...commonProps} />
      }

      if (hasAttachments) {
        return <FaPaperclip {...commonProps} />
      }

      return null
    }
  )

  const contactName = useMemo(() => {
    if (document.contact_name) return document.contact_name
    if (document.import_data?.contact_hint) return document.import_data.contact_hint
    return ''
  }, [document])

  const documentSrc = useMemo(() => {
    return getDocumentsPageUrl(businessId, { ...params, id: document.id })
  }, [businessId, document.id, params])

  return (
    <>
      <StyledListItem
        ref={documentRef}
        id={`list-item-document-${document.id}`}
        className={classNames({
          'first-of-type': isFirstOfType,
          'last-of-type': isLastOfType
        })}
        to={documentSrc}
        selected={isSelected}
      >
        <ItemRow className="margin-bottom">
          {<NotificationIcon type={selectUserNotificationIcon(document, true)} />}
          {document.is_draft ? <DraftBadge /> : <ItemNumber>{document.number}</ItemNumber>}
          <Spacer />
          <ItemDate>{formatDate(document.date)}</ItemDate>
          <Icon
            isFlagged={document.is_flagged}
            isLocked={document.is_locked}
            hasAttachments={documentHasAttachment(document.attachment_ids)}
          />
        </ItemRow>
        <ItemRow>
          <ItemContact>{contactName}</ItemContact>
          <Spacer />
          <ItemBalance>
            <MonetaryAmount value={document.balance} showColor />
          </ItemBalance>
        </ItemRow>
        <ItemRow>
          <ItemDescription>{document.description}</ItemDescription>
        </ItemRow>
        {balanceResult && <BalanceMarker result={balanceResult} />}
      </StyledListItem>
    </>
  )
}

const StyledListItem = styled(Link).attrs({
  onClick: e => {
    if (e.shiftKey || e.metaKey) {
      e.preventDefault()
    }
  }
})<{ selected: boolean }>`
  padding: ${({ theme }) => theme.spacing.md}rem;
  border-bottom: 2px solid #f6f7f9;
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${({ theme, selected }) =>
    selected ? theme.colors.defaultHover : theme.colors.neutralWhite};
  text-decoration: none !important;
  color: ${({ theme }) => theme.colors.neutralBlack};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;

  &:hover {
    .description {
      color: ${({ theme }) => theme.colors.nocfoBlue};
      text-decoration: none !important;
    }
  }

  &.first-of-type {
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
  }

  &.last-of-type {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-bottom: 0;
  }
`

const ItemRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  align-items: center;
  height: 1.2rem;

  &.margin-bottom {
    margin-bottom: 4px;
  }
`

const ItemDate = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  color: ${({ theme }) => theme.colors.metalGray};
`

const ItemContact = styled.div`
  color: ${({ theme }) => theme.colors.neutralBlack};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ItemBalance = styled.div``

const ItemDescription = styled.span.attrs({ className: 'description' })`
  color: ${({ theme }) => theme.colors.neutralBlack};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${({ theme }) => theme.fontSize.md}rem;
`

const ItemNumber = styled.span`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  color: ${({ theme }) => theme.colors.metalGray};
  font-variant-numeric: tabular-nums;
  border-radius: 0.4rem;
`

const Spacer = styled.div`
  flex: 1;
`
